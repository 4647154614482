/** @format */

// ** Pages
import Home from '../pages/Home'
import AboutUs from '../pages/AboutUs'
import Services from '../pages/Services'
import Portfolio from '../pages/Portfolio'
import Blogs from '../pages/Blogs'
import Careers from '../pages/Careers'
import ContactUs from '../pages/ContactUs'

export const routes = [
	{
		path: '/',
		name: 'home',
		element: <Home />,
	},
	{
		path: '/about-us',
		name: 'about_us',
		element: <AboutUs />,
	},
	{
		path: '/services',
		name: 'services',
		element: <Services />,
	},
	{
		path: '/portfolio',
		name: 'portfolio',
		element: <Portfolio />,
	},
	{
		path: '/blogs',
		name: 'blogs',
		element: <Blogs />,
	},
	{
		path: '/careers',
		name: 'careers',
		element: <Careers />,
	},
	{
		path: '/contact-us',
		name: 'contact_us',
		element: <ContactUs />,
	},
]
