/** @format */

// ** Components
import Typography from '@/components/Typography'
import PersonalCard from '@/components/PersonalCard'

const founders = [
	{
		id: 1,
		name: 'Name name',
		title: 'CO Founder',
		img: '',
		body: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque nobis, veritatis earum itaque molestiae deserunt incidunt ipsum labore a numquam reprehenderit dicta voluptatum magnam corporis id illo autem fugit optio.        ',
		facebook: 'https://www.facebook.com',
		linkedin: 'https://www.linkedin.com',
	},
	{
		id: 2,
		name: 'Name name',
		title: 'CO Founder',
		img: '',
		body: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque nobis, veritatis earum itaque molestiae deserunt incidunt ipsum labore a numquam reprehenderit dicta voluptatum magnam corporis id illo autem fugit optio.        ',
		facebook: 'https://www.facebook.com',
		linkedin: 'https://www.linkedin.com',
	},
	{
		id: 3,
		name: 'Name name',
		title: 'CO Founder',
		img: '',
		body: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque nobis, veritatis earum itaque molestiae deserunt incidunt ipsum labore a numquam reprehenderit dicta voluptatum magnam corporis id illo autem fugit optio.        ',
		facebook: 'https://www.facebook.com',
		linkedin: 'https://www.linkedin.com',
	},
	{
		id: 4,

		name: 'Name name',
		title: 'CO Founder',
		img: '',
		body: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque nobis, veritatis earum itaque molestiae deserunt incidunt ipsum labore a numquam reprehenderit dicta voluptatum magnam corporis id illo autem fugit optio.        ',
		facebook: 'https://www.facebook.com',
		linkedin: 'https://www.linkedin.com',
	},
	{
		id: 5,
		name: 'Name name',
		title: 'CO Founder',
		img: '',
		body: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque nobis, veritatis earum itaque molestiae deserunt incidunt ipsum labore a numquam reprehenderit dicta voluptatum magnam corporis id illo autem fugit optio.        ',
		facebook: 'https://www.facebook.com',
		linkedin: 'https://www.linkedin.com',
	},
	{
		id: 6,
		name: 'Name name',
		title: 'CO Founder',
		img: '',
		body: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque nobis, veritatis earum itaque molestiae deserunt incidunt ipsum labore a numquam reprehenderit dicta voluptatum magnam corporis id illo autem fugit optio.        ',
		facebook: 'https://www.facebook.com',
		linkedin: 'https://www.linkedin.com',
	},
	{
		id: 7,
		name: 'Name name',
		title: 'CO Founder',
		img: '',
		body: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque nobis, veritatis earum itaque molestiae deserunt incidunt ipsum labore a numquam reprehenderit dicta voluptatum magnam corporis id illo autem fugit optio.        ',
		facebook: 'https://www.facebook.com',
		linkedin: 'https://www.linkedin.com',
	},
]

const OurFounders = () => {
	return (
		<section className='py-16'>
			<div className='container mx-auto'>
				<Typography variant={'2XL'} as={'h2'} className={'text-secondary mb-4'}>
					You want to know mote about CNEPHO !
				</Typography>
				<Typography
					variant={'5XL'}
					as={'div'}
					className={'text-white font-bold max-w-[40rem] mb-6'}>
					<p className='md:leading-snug'>Meet Our Founders</p>
				</Typography>
				<Typography variant={'XL'} as={'div'} className='tracking-wider text-white '>
					<p className='md:leading-loose'>
						Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cum ad rerum velit nisi
						ducimus ipsum beatae quidem debitis saepe earum voluptate iste laborum fuga, assumenda
						delectus cumque autem esse consequuntur! Lorem, ipsum dolor sit amet consectetur
						adipisicing elit. Necessitatibus illo doloremque nam aliquam et distinctio quis aperiam
						fuga iure optio non assumenda, eos praesentium ab eligendi consequatur ullam nisi
						quibusdam.
					</p>
				</Typography>

				<div className='grid lg:grid-cols-3 md:grid-cols-2 gap-14 mt-14'>
					{founders.map((item) => (
						<PersonalCard
							key={item.id}
							name={item.name}
							title={item.title}
							body={item.body}
							facebookURL={item.facebook}
							linkedinURL={item.linkedin}
							labelColor='#313c74'
						/>
					))}
				</div>
			</div>
		</section>
	)
}

export default OurFounders
