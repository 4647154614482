/** @format */

// ** React
import { useTranslation } from 'react-i18next'

// ** Icons
import { IoIosArrowRoundForward } from 'react-icons/io'
import { IoIosArrowRoundBack } from 'react-icons/io'

// ** Components
import Typography from '@/components/Typography'

// ** Contents
import { WORK, WORK_SECTION_TITLE } from '@/constants/homePage'
import Button from '@/components/Button'

const OurWork = () => {
	const { t, i18n } = useTranslation('translations')

	return (
		<section className='md:pt-28 pt-24'>
			<div className='container mx-auto'>
				{/* Section Title */}
				<div className='flex justify-center items-center gap-3 mb-8 md:mb-16'>
					<Typography variant={'4XL'} as={'span'} className='text-secondary'>
						//
					</Typography>
					<Typography variant={'4XL'} as={'h2'} className='text-white font-bold'>
						{t(WORK_SECTION_TITLE)}
					</Typography>
				</div>

				<div className='flex flex-col gap-24 md:gap-12 '>
					{WORK.map((work) => (
						<div
							key={work.id}
							className='relative h-[22rem] lg:h-[35rem] w-full p-4 lg:p-16 items-center justify-center bg-softGray rounded-2xl'>
							<div className='lg:w-1/2 h-full flex flex-col gap-4 lg:gap-10 '>
								<Typography
									variant={'4XL'}
									as={'h3'}
									className='text-primary tracking-wider font-bold'>
									{t(work.title)}
								</Typography>
								<div className=''>
									<Typography
										variant={'SM'}
										as={'span'}
										className='text-primary py-1 px-2 lg:px-4 rounded-2xl border border-primary mr-1 lg:mr-4'>
										{t(work.tag_1)}
									</Typography>
									<Typography
										variant={'SM'}
										as={'span'}
										className='text-primary py-1 px-2 lg:px-4 rounded-2xl border border-primary mr-1 lg:mr-4'>
										{t(work.tag_2)}
									</Typography>
								</div>
								<Typography variant={'LG'} as={'p'} className='text-primary font-bold '>
									{t(work.subtitle_1)}
								</Typography>
								<Typography variant={'MD'} as={'p'} className='text-primary max-w-lg'>
									{t(work.subtitle_2)}
								</Typography>

								<Button className='bg-primary hover:bg-white hover:text-primary flex items-center gap-2 mt-2 md:text-md text-xs text-white py-1 px-2 w-fit rounded-2xl'>
									{t(work.button_text)}
									{i18n.dir() === 'ltr' ? (
										<IoIosArrowRoundForward className='text-xl md:text-3xl' />
									) : (
										<IoIosArrowRoundBack className='text-xl md:text-3xl' />
									)}
								</Button>
							</div>
							<div
								className={`absolute transform top-full max-lg:-mt-20
							    lg:top-1/2 lg:-translate-y-1/2 ${
										i18n.dir() === 'rtl'
											? 'lg:left-1/4 lg:-translate-x-1/2 -left-2'
											: 'lg:right-1/4 lg:translate-x-1/2 -right-2'
									}
							`}>
								<img src={work.imgSrc} alt={work.imgAlt} className='lg:w-[25rem] w-48' />
							</div>
						</div>
					))}
				</div>
			</div>
		</section>
	)
}

export default OurWork
