/** @format */

import { IoLogoInstagram } from 'react-icons/io'
import { FaFacebookF } from 'react-icons/fa'
import { FaXTwitter } from 'react-icons/fa6'
import { BiMap } from 'react-icons/bi'
import { MdOutlineEmail } from 'react-icons/md'
import { MdOutlinePhoneIphone } from 'react-icons/md'

export const DESCRIPTION_TITLE = 'description'

export const USEFUL_LINK_TITLE = 'useful_link'
export const USEFUL_LINK_LINKS = [
	{
		title: 'about_us',
		href: '/about-us',
	},
	{
		title: 'news',
		href: '/news',
	},
	{
		title: 'contact_us',
		href: '/contact-us',
	},
	{
		title: 'case_study',
		href: '/case-study',
	},
	{
		title: 'link_1',
		href: '/link-1',
	},
	{
		title: 'link_2',
		href: '/link-2',
	},
]

export const SERVICES_TITLE = 'services'
export const SERVICES_LINKS = [
	{
		title: 'ui_ux',
		href: '/ui_ux',
	},
	{
		title: 'branding',
		href: '/branding',
	},
	{
		title: 'web_development',
		href: '/web_development',
	},
	{
		title: 'application_development',
		href: '/application_development',
	},
	{
		title: 'service_1',
		href: '/service_1',
	},
	{
		title: 'service_2',
		href: '/service_2',
	},
]

export const INFORMATION_TITLE = 'information'
export const INFORMATION_LINKS = [
	{
		name: 'phone_number',
		value: '+9633333333333',
		href: 'tel:+9633333333333',
		img: <MdOutlinePhoneIphone />,
	},
	{
		name: 'address',
		value: 'Lebanon, Beirut',
		href: '',
		img: <BiMap />,
	},
	{
		name: 'email',
		value: 'test@test.com',
		href: 'mailto:test@test.com',
		img: <MdOutlineEmail />,
	},
]

export const SOCIAL_MEDIA = [
	{
		name: 'facebook',
		href: 'www.facebook.com',
		logo: <FaFacebookF />,
	},
	{
		name: 'instagram',
		href: 'www.instagram.com',
		logo: <IoLogoInstagram />,
	},
	{
		name: 'x',
		href: 'www.x.com',
		logo: <FaXTwitter />,
	},
]
