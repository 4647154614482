/** @format */

import Typography from './Typography'

import { FaFacebook } from 'react-icons/fa'
import { BsLinkedin } from 'react-icons/bs'

const PersonalCard = ({ name, title, body, labelColor = '', facebookURL, linkedinURL }) => {
	return (
		<div className='group relative overflow-hidden border border-white rounded-2xl aspect-9/12'>
			<img src='./assets/aboutPage/person.png' className='object-contain object-center w-full' />
			{/* Label */}
			<div className='absolute h-full bottom-0 w-full overflow-hidden'>
				<svg
					className='absolute bottom-2 z-0'
					viewBox='0 0 365 195'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'>
					<path
						d='M0 54.5896C0 50.7487 2.72962 47.4499 6.50261 46.731L249.79 0.374708C251.081 0.128696 252.413 0.204587 253.668 0.595673L338.795 27.127L359.609 34.3093C362.835 35.4224 365 38.4592 365 41.8717V187C365 191.418 361.418 195 357 195H8C3.58173 195 0 191.418 0 187V54.5896Z'
						fill={`${labelColor}`}
					/>
				</svg>

				<svg
					className='absolute bottom-0'
					viewBox='0 0 365 195'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'>
					<path
						d='M0 54.5896C0 50.7487 2.72962 47.4499 6.50261 46.731L249.79 0.374708C251.081 0.128696 252.413 0.204587 253.668 0.595673L338.795 27.127L359.609 34.3093C362.835 35.4224 365 38.4592 365 41.8717V187C365 191.418 361.418 195 357 195H8C3.58173 195 0 191.418 0 187V54.5896Z'
						fill='#F1F4FF'
					/>
				</svg>

				<Typography
					variant={'4XL'}
					as={'p'}
					className={`absolute text-nowrap h-1/4 font-bold bottom-0 right-1/2 transform transition-all duration-700 translate-x-1/2 text-primary z-20 
							group-hover:-translate-y-full group-hover:text-white group-hover:h-1/2 group-hover:pt-16
						`}>
					{name}
				</Typography>

				<Typography
					variant={'XL'}
					as={'p'}
					className={`absolute text-nowrap h-1/6 font-semibold bottom-0 right-1/2 transform transition-all duration-700 translate-x-1/2 text-secondary z-20
								group-hover:-translate-y-full group-hover:h-1/2 group-hover:pt-28
						`}>
					{title}
				</Typography>

				<div
					className={
						'absolute top-0 md:p-8 p-4 bg-primary h-full translate-y-full transform transition-all duration-500  group-hover:translate-y-0 z-10'
					}>
					<div className='flex flex-col h-full justify-end gap-6 max-md:pb-10'>
						<Typography variant={'LG'} as={'div'} className={'text-white tracking-wider'}>
							<p className='leading-loose'>{body}</p>
						</Typography>
						<div className='flex gap-6 text-white text-4xl'>
							<a href={facebookURL}>
								<FaFacebook className='cursor-pointer hover:text-secondary' />
							</a>
							<a href={linkedinURL}>
								<BsLinkedin className='cursor-pointer hover:text-secondary' />
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default PersonalCard
