/** @format */

import { useTranslation } from 'react-i18next'

// ** Icons
import { IoIosArrowRoundForward } from 'react-icons/io'
import { IoIosArrowRoundBack } from 'react-icons/io'

// ** Components
import Typography from '@/components/Typography'
import Button from '@/components/Button'

const BookAppointment = () => {
	const { t, i18n } = useTranslation('translations')

	return (
		<section className='md:py-16 py-10 bg-softGray'>
			<div className='container mx-auto flex flex-col gap-4 text-center max-w-[55rem]'>
				<Typography variant={'XL'} as={'p'} className='text-primary font-medium'>
					Lorem ipsum dolor sit amet consectetur adipisicing
				</Typography>
				<Typography variant={'4XL'} as={'p'} className='text-primary font-bold tracking-wide'>
					{t('READY TO CREATE A NEW PROJECT')}
				</Typography>

				<Typography variant={'MD'} as={'p'} className='text-primary tracking-wide leading-loose'>
					Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum obcaecati corporis ad
					earum. Minima vel architecto quis id delectus, deleniti blanditiis enim incidunt repellat
					dignissimos, necessitatibus optio dolore reiciendis fuga.
				</Typography>
				<Button className='flex items-center gap-1 font-semibold mx-auto bg-primary text-softGray hover:bg-white hover:text-primary'>
					<Typography variant={'base'} as={'span'} className=''>
						Schedule An Appointment
					</Typography>
					{i18n.dir() === 'ltr' ? (
						<IoIosArrowRoundForward className='text-2xl ' />
					) : (
						<IoIosArrowRoundBack className='text-2xl ' />
					)}
				</Button>
			</div>
		</section>
	)
}

export default BookAppointment
