/** @format */

// ** React
import { useTranslation } from 'react-i18next'
import { FaRegCopyright } from 'react-icons/fa6'

// ** Contents
import { LOGO_IMG_SRC } from '@/constants/global'
import {
	DESCRIPTION_TITLE,
	INFORMATION_LINKS,
	INFORMATION_TITLE,
	SERVICES_LINKS,
	SERVICES_TITLE,
	SOCIAL_MEDIA,
	USEFUL_LINK_LINKS,
	USEFUL_LINK_TITLE,
} from '@/constants/footer'

// ** Components
import Typography from '@/components/Typography'

const Footer = () => {
	const { t } = useTranslation('translations', { keyPrefix: 'footer' })

	return (
		<footer className='bg-primary mt-16'>
			<div className='container mx-auto w-full'>
				<div className='grid lg:grid-cols-5 md:grid-cols-2 gap-8 justify-between'>
					{/* logo */}
					<div className='md:col-start-1 md:col-end-3'>
						<img src={LOGO_IMG_SRC} alt='company-logo' className='aspect-square mb-4' />
						<p className='text-white'>{t(DESCRIPTION_TITLE)}</p>
					</div>

					{/* Useful links */}
					<div className='flex flex-col md:gap-1 text-white'>
						<h3 className='mb-1 md:mb-4 font-bold md:text-2xl text-xl'>{t(USEFUL_LINK_TITLE)}</h3>
						{USEFUL_LINK_LINKS.map((link) => (
							<div key={link.title} className='hover:text-secondary'>
								<a href={link.href}>{t(link.title)}</a>
							</div>
						))}
					</div>

					{/* services */}
					<div className='flex flex-col md:gap-1 text-white'>
						<h3 className='mb-1 md:mb-4 font-bold md:text-2xl text-xl'>{t(SERVICES_TITLE)}</h3>
						{SERVICES_LINKS.map((link) => (
							<div key={link.title} className='hover:text-secondary'>
								<a href={link.href}>{t(link.title)}</a>
							</div>
						))}
					</div>

					{/* information */}
					<div className='flex flex-col md:gap-1 text-white'>
						<h3 className='mb-1 md:mb-4 font-bold md:text-2xl text-xl'>{t(INFORMATION_TITLE)}</h3>
						{INFORMATION_LINKS.map((link) => (
							<div key={link.name} className='hover:text-secondary flex items-center gap-2'>
								<span className='text-xl'>{link.img}</span>
								<a href={link.href}>{t(link.value)}</a>
							</div>
						))}
						<div className='flex flex-row gap-3'>
							{SOCIAL_MEDIA.map((item) => (
								<button key={item.name} className='mt-3 text-xl flex hover:text-secondary'>
									{item.logo}
								</button>
							))}
						</div>
					</div>
				</div>
				<div className='flex md:flex-row flex-col justify-between gap-4 mt-12 md:py-10 py-4  border-t border-softGray text-softGray'>
					<Typography variant={'MD'} as={'p'} className='flex gap-1 items-center'>
						<FaRegCopyright />
						2023 cNepho IT service company, All Right Reserve
					</Typography>
					<div className='flex md:gap-8 gap-2'>
						<Typography variant={'MD'} as={'p'}>
							Privacy Policy
						</Typography>
						|
						<Typography variant={'MD'} as={'p'}>
							Term and Condition
						</Typography>
					</div>
				</div>
			</div>
		</footer>
	)
}

export default Footer
