/** @format */

import React from 'react'

const TextInput = (props) => {
	const { name, onChange, value, label, placeholder, className } = props

	return (
		<div className='flex flex-col gap-2'>
			<label htmlFor={name} className='text-primary font-medium'>
				{label}
			</label>
			<textarea
				name={name}
				value={value}
				onChange={onChange}
				placeholder={placeholder}
				className={`w-full p-2 resize-none scroll-m-2 rounded-xl text-lg text-primary focus-visible:outline-none text-left rtl:text-right ${className}`}
			/>
		</div>
	)
}

export default TextInput
