/** @format */

import React from 'react'

const StickyButton = () => {
	return (
		<div
			className='fixed hover:-right-16 -right-20 top-2/3 transform -translate-y-[-100px] z-40
            bg-[#D4DFFF] hover:bg-[#bfcbf0] transition-all text-primary border-2 border-primary w-[150px] h-[50px] py-[8px] px-[24px] rounded-full cursor-pointer
            '>
			<div className='flex justify-start items-center'>
				<svg
					width='30'
					height='30'
					viewBox='0 0 34 34'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'>
					<path
						d='M22.85 0.5C17.15 0.65 12.5 5.3 12.5 11C12.5 16.85 17.15 21.5 23 21.5C28.85 21.5 33.5 16.85 33.5 11C33.5 5.15 28.85 0.5 22.85 0.5ZM23 3.5C27.2 3.5 30.5 6.8 30.5 11C30.5 15.2 27.2 18.5 23 18.5C18.8 18.5 15.5 15.2 15.5 11C15.5 6.8 18.8 3.5 23 3.5ZM21.5 5V12.5L26.9 15.8L28.1 14L23.75 11.45V5H21.5ZM5.9 18.2C8 22.4 11.6 26 15.8 28.1L19.1 24.8C19.55 24.35 20.15 24.2 20.6 24.5C22.25 25.1 24.05 25.4 26 25.4C26.75 25.4 27.5 26.15 27.5 26.9V32C27.5 32.75 26.75 33.5 26 33.5C11.9 33.5 0.5 22.1 0.5 8C0.5 7.1 1.25 6.5 2 6.5H7.25C8.15 6.5 8.75 7.1 8.75 8C8.75 9.8 9.05 11.6 9.65 13.4C9.8 14 9.65 14.45 9.35 14.9L5.9 18.2Z'
						fill='#313D72'
					/>
				</svg>
			</div>
		</div>
	)
}

export default StickyButton
