/** @format */

// ** Components
import Typography from '@/components/Typography'

const WhoWeAre = () => {
	return (
		<section className='py-16'>
			<div className='container mx-auto flex max-md:flex-col-reverse justify-between gap-6'>
				<div className='md:w-1/2 flex flex-col md:gap-14 gap-8'>
					<Typography variant={'2XL'} as={'h2'} className={'text-secondary -mb-8'}>
						Who we are
					</Typography>
					<Typography variant={'5XL'} as={'p'} className={'text-white font-bold tracking-wider'}>
						An essential aspects of creativity is not afraid to fail
					</Typography>
					<Typography variant={'2XL'} as={'p'} className={'text-white'}>
						Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cum ad rerum velit nisi
						ducimus ipsum beatae quidem debitis saepe earum voluptate iste laborum fuga, assumenda
						delectus cumque autem esse consequuntur!
					</Typography>
					<div className='flex flex-col gap-6'>
						<div className='grid grid-cols-2 gap-2'>
							<Typography variant={'ND'} as={'p'} className={'text-white font-bold'}>
								CONSULTING
							</Typography>
							<Typography
								variant={'ND'}
								as={'p'}
								className={'text-white font-bold text-right rtl:text-left'}>
								85%
							</Typography>
							<div className='w-full bg-white rounded-full h-2.5 col-start-1 col-end-3 '>
								<div className={`bg-secondary h-2.5 rounded-full w-[85%]`}></div>
							</div>
						</div>
						<div className='grid grid-cols-2 gap-2'>
							<Typography variant={'ND'} as={'p'} className={'text-white font-bold'}>
								CONSULTING
							</Typography>
							<Typography
								variant={'ND'}
								as={'p'}
								className={'text-white font-bold text-right rtl:text-left'}>
								85%
							</Typography>
							<div className='w-full bg-white rounded-full h-2.5 col-start-1 col-end-3 '>
								<div className={`bg-secondary h-2.5 rounded-full w-[85%]`}></div>
							</div>
						</div>
						<div className='grid grid-cols-2 gap-2'>
							<Typography variant={'ND'} as={'p'} className={'text-white font-bold'}>
								CONSULTING
							</Typography>
							<Typography
								variant={'ND'}
								as={'p'}
								className={'text-white font-bold text-right rtl:text-left'}>
								85%
							</Typography>
							<div className='w-full bg-white rounded-full h-2.5 col-start-1 col-end-3 '>
								<div className={`bg-secondary h-2.5 rounded-full w-[85%]`}></div>
							</div>
						</div>
					</div>
				</div>
				<div className='relative md:w-1/2 md:pl-12'>
					{/* img */}
					<span className='w-full h-full absolute bottom-0 right-0 transform border-2 border-secondary rounded-2xl scale-90 -rotate-12 rtl:rotate-12 -z-10' />
					<div className='bg-whoWeAre w-full h-full rounded-2xl aspect-square bg-no-repeat bg-cover bg-center' />
					{/* label */}
					<span className='md:size-36 size-24 absolute -bottom-6 right-10 transform rtl:-translate-x-[250%]  border-2 border-secondary rounded-2xl rotate-45 ' />
					<div className='md:size-36 size-24 absolute -bottom-10 right-10 transform rtl:-translate-x-[250%] bg-white rounded-2xl rotate-45 z-0 overflow-hidden'>
						<div className='text-center absolute top-1/2 right-1/2 transform translate-x-1/2 -translate-y-1/2 -rotate-45'>
							<Typography variant={'4XL'} as={'p'} className={'font-extrabold text-primary'}>
								12
							</Typography>
							<Typography variant={'XL'} as={'p'} className={'leading-none text-primary'}>
								years of experience
							</Typography>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default WhoWeAre
