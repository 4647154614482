/** @format */

import Typography from '@/components/Typography'
import React from 'react'

const WhyChoseUs = () => {
	return (
		<section className='py-16'>
			<div className='container mx-auto flex max-md:flex-col justify-between items-center lg:gap-40 gap-14'>
				{/* img */}
				<div className='md:w-4/12 relative aspect-9/12 h-full w-[15rem]'>
					<div className='w-full h-full bg-secondary absolute -bottom-6 -right-6  transform rtl:-translate-x-[15%] rounded-2xl -z-10' />
					<div className='w-full h-full  bg-whoWeAre rounded-2xl bg-no-repeat bg-cover bg-center' />
				</div>
				<div className='md:w-7/12 flex flex-col md:gap-14 gap-8'>
					<Typography variant={'2XL'} as={'h2'} className={'text-secondary -mb-8'}>
						Why Choose Us
					</Typography>
					<Typography variant={'5XL'} as={'div'} className={'text-white font-bold max-w-[40rem]'}>
						<p className='md:leading-snug'>Highly experienced people with us</p>
					</Typography>
					<Typography variant={'XL'} as={'div'} className='tracking-wider text-white '>
						<p className='md:leading-loose'>
							Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cum ad rerum velit nisi
							ducimus ipsum beatae quidem debitis saepe earum voluptate iste laborum fuga, assumenda
							delectus cumque autem esse consequuntur! Lorem, ipsum dolor sit amet consectetur
							adipisicing elit. Necessitatibus illo doloremque nam aliquam et distinctio quis
							aperiam fuga iure optio non assumenda, eos praesentium ab eligendi consequatur ullam
							nisi quibusdam.
						</p>
					</Typography>
				</div>
			</div>
		</section>
	)
}

export default WhyChoseUs
