/** @format */

// ** React
import { useTranslation } from 'react-i18next'

// ** Icons
import { BiSolidQuoteAltLeft } from 'react-icons/bi'

// ** Components
import Typography from '@/components/Typography'

const clients = [
	{
		id: 1,
		name: 'Jane Roe',
		position: 'CEO Expert',
		img: '',
		quota:
			'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum corporis vero voluptate provident ipsam iure accusamus sit ab omnis quasi? Nihil nisi porro, alias dignissimos sed laudantium assumenda facilis explicabo! ',
	},
	{
		id: 2,
		name: 'Jane Roe',
		position: 'CEO Expert',
		img: '',
		quota:
			'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum corporis vero voluptate provident ipsam iure accusamus sit ab omnis quasi? Nihil nisi porro, alias dignissimos sed laudantium assumenda facilis explicabo! ',
	},
	{
		id: 3,
		name: 'Jane Roe',
		position: 'CEO Expert',
		img: '',
		quota:
			'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum corporis vero voluptate provident ipsam iure accusamus sit ab omnis quasi? Nihil nisi porro, alias dignissimos sed laudantium assumenda facilis explicabo! ',
	},
	{
		id: 4,
		name: 'Jane Roe',
		position: 'CEO Expert',
		img: '',
		quota:
			'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum corporis vero voluptate provident ipsam iure accusamus sit ab omnis quasi? Nihil nisi porro, alias dignissimos sed laudantium assumenda facilis explicabo! ',
	},
]

const Testimonials = () => {
	const { t, i18n } = useTranslation('translations')

	return (
		<section className='py-28'>
			<div className='container mx-auto'>
				<div className='flex justify-center items-center gap-3 mb-10'>
					<Typography variant={'4XL'} as={'span'} className='text-secondary'>
						//
					</Typography>
					<Typography variant={'4XL'} as={'h2'} className='text-white font-bold'>
						{t("Satisfied Client's")}
					</Typography>
				</div>

				<div className='grid md:grid-cols-2 md:grid-rows-2 gap-10'>
					{clients.map((client) => (
						<div
							key={client.id}
							className='flex md:flex-row flex-col gap-4 items-center justify-between'>
							<div className='size-24 rounded-full border border-secondary mx-auto md:-mt-16'>
								<img src={client.img} alt='' />
							</div>
							<div className='md:w-9/12 flex gap-3'>
								<BiSolidQuoteAltLeft
									className={`-mt-2 transform text-secondary text-2xl self-start ${
										i18n.dir() === 'rtl' ? '-scale-y-100 rotate-180' : ''
									}`}
								/>
								<div className='md:w-10/12 w-5/6 text-white flex flex-col gap-1'>
									<Typography variant={'MD'} as={'p'} className={'italic tracking-wide'}>
										{client.quota}
									</Typography>
									<Typography variant={'LG'} as={'p'} className={'font-bold mt-4'}>
										{client.name}
									</Typography>
									<Typography variant={'MD'} as={'p'}>
										{client.position}
									</Typography>
								</div>
								<BiSolidQuoteAltLeft
									className={`md:-mb-2 -mb-28 transform text-secondary text-2xl self-center ${
										i18n.dir() === 'rtl' ? '-scale-y-100 ' : '-scale-100'
									}`}
								/>
							</div>
						</div>
					))}
				</div>
			</div>
		</section>
	)
}

export default Testimonials
