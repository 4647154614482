/** @format */

import React from 'react'

const TextareaInput = (props) => {
	const { name, onChange, value, label, placeholder, className, type } = props

	return (
		<div className='flex flex-col gap-2'>
			<label htmlFor={name} className='text-primary font-medium'>
				{label}
			</label>
			<input
				type={type}
				name={name}
				value={value}
				onChange={onChange}
				placeholder={placeholder}
				className={`w-full h-10 p-2 md:text-2xl text-lg rounded-xl text-primary focus-visible:outline-none text-left rtl:text-right ${className}`}
			/>
		</div>
	)
}

export default TextareaInput
