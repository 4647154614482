/** @format */

import { useQuery } from '@tanstack/react-query'
import i18n from 'i18next'

const BASE_URL = 'https://api.test.cnepho.com/api'

const fetchData = async (method, path) => {
	const res = await fetch(`${BASE_URL}/${path}`, {
		method,
		headers: {
			// 'X-localization': i18n.language,
		},
	})
	const data = await res.json()

	return data
}

const useReactQuery = ({ method, path }) => {
	return useQuery({
		queryKey: [path],
		queryFn: () => fetchData(method, path),
	})
}

export default useReactQuery
