/** @format */

import Typography from '@/components/Typography'

const numbers = [
	{
		number: '40K',
		name: 'Global Happy Client',
	},
	{
		number: '255',
		name: 'Global Happy Client',
	},
	{
		number: '999',
		name: 'Global Happy Client',
	},
	{
		number: '40K',
		name: 'Global Happy Client',
	},
]

const NumbersAboutUs = () => {
	return (
		<section className='py-16 bg-blue-950'>
			<div className='container mx-auto'>
				<Typography variant={'2XL'} as={'h2'} className={'mb-8 text-secondary'}>
					Numbers About CNEPHO
				</Typography>
				<div className='flex md:flex-row flex-col gap-6 md:items-end'>
					<div className='md:md:w-5/12 flex flex-col gap-4'>
						<Typography variant={'5XL'} as={'div'} className={'text-white font-bold max-w-[40rem]'}>
							<p className='md:leading-snug'>Our Fun Fact</p>
						</Typography>
						<Typography
							variant={'XL'}
							as={'div'}
							className='tracking-wider text-white max-w-[30rem]'>
							<p className='leading-loose'>
								Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cum ad rerum velit nisi
								ducimus ipsum beatae quid
							</p>
						</Typography>
					</div>
					<div className='md:md:w-7/12 grid sm:grid-cols-2 grid-cols-1 w-full gap-8'>
						{numbers.map((item, idx) => (
							<div key={idx} className={`flex flex-col`}>
								<div className='flex gap-1 items-start '>
									<Typography variant={'6XL'} as={'p'} className={'font-bold text-white'}>
										{item.number}
									</Typography>
									<Typography variant={'2XL'} as={'p'} className={' text-secondary'}>
										+
									</Typography>
								</div>
								<Typography variant={'XL'} as={'p'} className={' text-white'}>
									{item.name}
								</Typography>
							</div>
						))}
					</div>
				</div>
			</div>
		</section>
	)
}

export default NumbersAboutUs
