/** @format */

// ** React
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'

// ** Components
import LottieAnimation from '@/components/lottie' // Adjust the path as needed
import animationDataHome2 from '@/components/HomePart1.json'
import Typography from '@/components/Typography'
import Button from '@/components/Button'

// ** Contents
import { HERO } from '@/constants/homePage'

// import { HERO_IMG_SRC } from '@/constants/global'

const containerVariants = {
	open: {
		transition: {
			staggerChildren: 0.1,
			delayChildren: 0.01,
		},
	},
	closed: {},
}
const itemVariants = {
	open: {
		x: 0,
		opacity: 1,
		transition: {
			type: 'spring',
			stiffness: 400,
			damping: 10,
		},
	},
	closed: {
		x: -50,
		opacity: 0,
	},
}

function Hero() {
	const { t } = useTranslation('translations', { keyPrefix: 'home_page.hero' })

	return (
		<section className='h-screen md:pt-40 pt-32'>
			<div className='container mx-auto flex flex-col lg:flex-row max-md:gap-10 justify-between items-center max-md:justify-evenly'>
				<motion.div
					className='flex flex-col max-md:items-center w-full md:w-1/2 gap-6 text-white max-md:text-center'
					variants={containerVariants}
					initial='closed'
					animate='open'>
					<motion.div variants={itemVariants}>
						<Typography
							variant='6XL'
							as='h1'
							className='font-extrabold tracking-widest whitespace-nowrap'>
							{t(HERO.title_1)}
						</Typography>
					</motion.div>

					<motion.div variants={itemVariants}>
						<Typography
							variant='6XL'
							as='h1'
							className='font-extrabold tracking-widest whitespace-nowrap'>
							{t(HERO.title_2)}
						</Typography>
					</motion.div>

					<motion.div variants={itemVariants}>
						<Typography variant='2XL' as='p' className={'max-w-[35rem]'}>
							{t(HERO.subtitle)}
						</Typography>
					</motion.div>

					<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.3 }}>
						<Button className={'hover:bg-white hover:text-primary text-white max-md:mt-10'}>
							{t(HERO.button_text)}
						</Button>
					</motion.div>
				</motion.div>
				<div className='w-full md:w-1/2 flex items-center'>
					{/* <img
					src={HERO_IMG_SRC}
					alt='My Image'
					className='w-full md:w-auto transform rtl:-scale-x-100'
				/> */}
					<div className='transform rtl:-scale-x-100'>
						<LottieAnimation animationData={animationDataHome2} />
					</div>
				</div>
			</div>
		</section>
	)
}

export default Hero
