/** @format */

import useReactQuery from '@/hooks/useReactQuery'
import i18n from 'i18next'

// ** Views
import Hero from '@/views/homePage/Hero'
import News from '@/views/homePage/News'
import OurClients from '@/views/homePage/OurClients'
import OurServices from '@/views/homePage/OurServices'
import OurWork from '@/views/homePage/OurWork'
import Technologies from '@/views/homePage/Technologies'
import Testimonials from '@/views/homePage/Testimonials'

const Home = () => {
	const { data, isLoading, isError, error, isFetching, refetch } = useReactQuery({
		method: 'GET',
		path: 'home_page',
	})

	return (
		<div className='flex flex-col'>
			<Hero />
			<OurServices />
			<OurWork />
			<Testimonials />
			<Technologies />
			<News />
			<OurClients />
		</div>
	)
}

export default Home
