/** @format */

import { Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// ** Components
import StickyButton from '@/components/StickyButton'
import BackgroundParallax from '@/components/BackgroundParallax'

// ** Views
import Header from '@/views/shared/Header'
import Footer from '@/views/shared/Footer'
import BookAppointment from '@/views/homePage/BookAppointment'
import ContactUs from '@/views/homePage/ContactUs'

import { BACKGROUND_WAVE_2_SRC } from '@/constants/global'
import { BACKGROUND_WAVE_SRC } from '@/constants/global'

const LandingLayout = () => {
	const { i18n } = useTranslation()

	return (
		<div dir={i18n.dir()}>
			<Header />
			<BackgroundParallax imgSrc={BACKGROUND_WAVE_SRC} className={'md:top-[15rem] top-[35rem]'} />
			<BackgroundParallax
				imgSrc={BACKGROUND_WAVE_2_SRC}
				className={'md:top-[90rem] top-[120rem]'}
			/>
			<BackgroundParallax imgSrc={BACKGROUND_WAVE_SRC} className={'md:top-[240rem] top-[190rem]'} />
			<BackgroundParallax
				imgSrc={BACKGROUND_WAVE_2_SRC}
				className={'md:top-[350rem] top-[270rem]'}
			/>

			<main>
				<Outlet />
			</main>

			<ContactUs />
			<BookAppointment />
			<StickyButton />
			<Footer />
		</div>
	)
}

export default LandingLayout
