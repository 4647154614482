/** @format */

import { useState } from 'react'
import { useTranslation } from 'react-i18next'

// ** Components
import TextInput from '@/components/TextInput'
import TextareaInput from '@/components/TextareaInput'
import Typography from '@/components/Typography'
import Button from '@/components/Button'

const ContactUs = () => {
	const { t } = useTranslation('translations')
	const [contactForm, setContactForm] = useState({
		name: '',
		phone: '',
		email: '',
		message: '',
	})

	const handelFromSubmit = (e) => {
		e.preventDefault()
		console.log('submitted', contactForm)
		// submit form
	}

	const handelChangeForm = (e) => {
		const { value, name } = e.target
		setContactForm({
			...contactForm,
			[name]: value,
		})
	}

	return (
		<section className='md:mt-28 md:py-16 py-10 bg-white'>
			<div className='container mx-auto flex md:flex-row flex-col gap-10'>
				<div className='md:w-2/3'>
					{/* Section Title */}
					<div className='flex gap-2 font-bold mb-14'>
						<Typography variant={'4XL'} as={'span'} className='text-secondary'>
							//
						</Typography>
						<Typography variant={'4XL'} as={'h2'} className='text-primary font-bold'>
							{t('MAKE AN APPOINTMENT')}
						</Typography>
					</div>

					{/* form */}
					<form onSubmit={handelFromSubmit}>
						<div className='grid md:grid-cols-2 grid-cols-1 md:gap-8 gap-4'>
							<div>
								<TextInput
									type='text'
									name='name'
									label='Your name'
									placeholder='Your Name'
									value={contactForm.name}
									onChange={handelChangeForm}
								/>
							</div>

							<div>
								<TextInput
									type='text'
									name='phone'
									label='Your Phone Number'
									placeholder='+9xxxxxxxxxxx'
									value={contactForm.phone}
									onChange={handelChangeForm}
								/>
							</div>

							<div>
								<TextInput
									type='email'
									name='email'
									label='Your Email'
									placeholder='your@email.com'
									value={contactForm.email}
									onChange={handelChangeForm}
								/>
							</div>

							<div className='md:row-start-1 md:row-end-4 md:col-start-2 md:col-end-3'>
								<TextareaInput
									name='message'
									label='Message'
									placeholder='Start typing here...'
									value={contactForm.message}
									onChange={handelChangeForm}
								/>
							</div>
						</div>
						<Button
							type='submit'
							className={'mt-10 text-primary font-semibold border border-primary'}>
							Send message
						</Button>
					</form>
				</div>

				<div className='md:w-1/3 md:p-8 p-4 border border-secondary rounded-xl'>
					<div className='flex flex-col gap-2'>
						<h3 className='font-medium text-2xl text-primary'>Prepare for a quick response!</h3>
						<span className='text-secondary'>cNepho@example.com</span>
						<span className='font-medium text-2xl text-primary'>Lebanon</span>
						<span className='text-softBlack '>Get directions | +966666666666666</span>
						<span className='text-softBlack '>Privacy Policy</span>
					</div>
					<div>
						<div className='grid md:grid-cols-1 grid-cols-2 md:gap-8 gap-2 gap-y-6 mt-6'>
							<div className='flex flex-row gap-2 items-center'>
								<div className='relative'>
									<img
										src=''
										alt=''
										className='md:size-16 size-14 border border-secondary rounded-full'
									/>
									<img src='' alt='' className='size-4 absolute bottom-0 rtl:left-0 ltr:right-0' />
								</div>
								<div className='text-softBlack'>
									<p>Name name</p>
									<p>CEO</p>
								</div>
							</div>
							<div className='flex flex-row gap-2 items-center'>
								<div className='relative'>
									<img
										src=''
										alt=''
										className='md:size-16 size-14 border border-secondary rounded-full'
									/>
									<img src='' alt='' className='size-4 absolute bottom-0 rtl:left-0 ltr:right-0' />
								</div>
								<div className='text-softBlack'>
									<p>Name name</p>
									<p>CEO</p>
								</div>
							</div>
							<div className='flex flex-row gap-2 items-center'>
								<div className='relative'>
									<img
										src=''
										alt=''
										className='md:size-16 size-14 border border-secondary rounded-full'
									/>
									<img src='' alt='' className='size-4 absolute bottom-0 rtl:left-0 ltr:right-0' />
								</div>
								<div className='text-softBlack'>
									<p>Name name</p>
									<p>CEO</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default ContactUs
