/** @format */

import React from 'react'

const Button = ({ children, className }) => {
	return (
		<button
			className={`transition-all border-2 py-2 px-6 rounded-full cursor-pointer ${className}`}>
			{children}
		</button>
	)
}

export default Button
