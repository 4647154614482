/** @format */

// ** React
import { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

// Icons
import { CgMenuRight } from 'react-icons/cg'
import { IoClose } from 'react-icons/io5'

// ** Contents
import { LOGO_IMG_SRC } from '@/constants/global'
import { routes } from '@/router/routes'

const variants = {
	open: {
		transition: {
			staggerChildren: 0.1,
		},
	},
	closed: {
		transition: {
			staggerChildren: 0.05,
			staggerDirection: -1,
		},
	},
}
const itemVariants = {
	open: {
		y: 0,
		opacity: 1,
	},
	closed: {
		y: 50,
		opacity: 0,
	},
}

function Header() {
	const [isOpen, setIsOpen] = useState(false)
	const [selected, setSelected] = useState('')
	const [showBg, setShowBg] = useState(false)

	const { t, i18n } = useTranslation('translations', { keyPrefix: 'home_page.header' })

	const handleLanguageChange = (event) => {
		i18n.changeLanguage(event.target.value)
	}

	const handleMenuClick = (menu) => {
		setSelected(menu)
		setIsOpen(false)
	}

	// toggle background color on header when scrolling
	useEffect(() => {
		const checkScrollHight = () => {
			if (window.scrollY < 70) setShowBg(false)
			else setShowBg(true)
		}
		window.addEventListener('scroll', checkScrollHight)

		// return window.removeEventListener('scroll', checkScrollHight)
	}, [isOpen])

	return (
		<header
			dir='ltr'
			className={`fixed w-full z-50 transform transition-all ${
				showBg ? 'bg-primary' : 'bg-transparent'
			}`}>
			<div className='container flex items-center justify-between md:py-2 py-1 mx-auto'>
				{/* Logo */}
				<div>
					<img src={LOGO_IMG_SRC} alt='Logo' className='md:w-24 w-16' />
				</div>
				{/* Language Switcher */}
				<div className='flex gap-10'>
					<div className='flex items-center'>
						<svg
							width='14'
							height='14'
							viewBox='0 0 16 16'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'>
							<g clipPath='url(#clip0_258_910)'>
								<path
									d='M7.99967 14.6666C11.6816 14.6666 14.6663 11.6818 14.6663 7.99992C14.6663 4.31802 11.6816 1.33325 7.99967 1.33325C4.31778 1.33325 1.33301 4.31802 1.33301 7.99992C1.33301 11.6818 4.31778 14.6666 7.99967 14.6666Z'
									stroke='white'
									strokeWidth='1.5'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
								<path
									d='M9.54004 5.33337L13.3667 11.96'
									stroke='white'
									strokeWidth='1.5'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
								<path
									d='M6.45996 5.33337H14.1133'
									stroke='white'
									strokeWidth='1.5'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
								<path
									d='M4.91992 7.99996L8.74659 1.37329'
									stroke='white'
									strokeWidth='1.5'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
								<path
									d='M6.46046 10.6667L2.63379 4.04004'
									stroke='white'
									strokeWidth='1.5'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
								<path
									d='M9.54005 10.6666H1.88672'
									stroke='white'
									strokeWidth='1.5'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
								<path
									d='M11.0796 8L7.25293 14.6267'
									stroke='white'
									strokeWidth='1.5'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
							</g>
							<defs>
								<clipPath id='clip0_258_910'>
									<rect width='16' height='16' fill='white' />
								</clipPath>
							</defs>
						</svg>

						<select
							className='bg-transparent text-white p-2 outline-none'
							value={i18n.language}
							onChange={handleLanguageChange}>
							<option value='en' className='bg-primary px-2 py-1 rounded-t-xl'>
								ENG
							</option>
							<option value='ar' className='bg-primary px-2 py-1 rounded-b-xl'>
								ARB
							</option>
						</select>
					</div>
					<button>
						<CgMenuRight
							onClick={() => setIsOpen(!isOpen)}
							className='text-4xl fill-current hover:text-[#00B9B9] transition-all  text-white'
						/>
					</button>
				</div>
				{/* Side Nav Background */}
				{isOpen && (
					<div
						className='fixed w-full h-full top-0 left-0'
						style={{ backgroundColor: '#0000007f' }}
						onClick={() => setIsOpen(false)}></div>
				)}
				{/* Side Nav */}
				<motion.nav
					className={`absolute right-0 top-0  h-screen bg-primary shadow-2xl z-30 transition-all duration-300 ${
						isOpen ? 'w-[320px] md:w-[360px] lg:w-[400px]' : 'w-0 -right-10'
					} `}
					variants={variants}
					initial='closed'
					animate={isOpen ? 'open' : 'closed'}>
					<div className={`flex justify-between items-center m-5`}>
						<div className='w-28'>
							<img src={LOGO_IMG_SRC} alt='' />
						</div>
						<button
							onClick={() => setIsOpen(!isOpen)}
							className='text-4xl fill-current cursor-pointer hover:text-secondary transition-all text-white'>
							<IoClose />
						</button>
					</div>
					<motion.ul
						className='text-white flex flex-col mt-10 md:mt-20 gap-8 ml-10 list-none'
						variants={variants}>
						{routes.map((route, idx) => (
							<motion.li
								key={route.name}
								onClick={() => handleMenuClick(route.name)}
								variants={itemVariants}
								whileHover={{ scale: 1.1 }}
								whileTap={{ scale: 0.95 }}
								className={`md:text-xl hover:text-secondary ${
									selected === route.name ? 'text-secondary' : ''
								}`}>
								<Link to={route.path}>{t(route.name).toUpperCase()}</Link>
							</motion.li>
						))}
					</motion.ul>
				</motion.nav>
			</div>
		</header>
	)
}

export default Header
