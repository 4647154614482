/** @format */

const tags = {
	h1: 'h1',
	h2: 'h2',
	h3: 'h3',
	h4: 'h4',
	h5: 'h5',
	body: 'p',
	'body-small': 'p',
	small: 'span',
}

const sizes = {
	'6XL': ' lg:text-6xl md:text-5xl sm:text-4xl text-3xl',
	'5XL': ' lg:text-5xl md:text-4xl sm:text-3xl text-2xl',
	'4XL': ' lg:text-4xl md:text-3xl sm:text-2xl text-xl',
	'3XL': ' lg:text-3xl md:text-2xl sm:text-xl text-lg',
	'2XL': ' lg:text-2xl md:text-xl sm:text-lg text-md',
	XL: ' lg:text-xl md:text-xl sm:text-lg text-md',
	LG: ' lg:text-lg md:text-md sm:text-base text-sm',
	MD: ' lg:text-md md:text-base sm:text-sm text-sm',

	SM: 'lg:text-md md:text-base text-xs',
	XS: 'md:text-sm text-xs',
}

const Typography = ({ variant, children, className, as }) => {
	const sizeClasses = sizes[variant]
	const Tag = as || tags[variant]

	return <Tag className={`${sizeClasses} ${className}`}>{children}</Tag>
}

export default Typography
