/** @format */

import { useRef } from 'react'
import { useScroll, useTransform, motion } from 'framer-motion'

const BackgroundParallax = ({ imgSrc, className }) => {
	const parallaxRef = useRef(null)
	const { scrollYProgress } = useScroll({
		target: parallaxRef.current,
		offset: ['start end', 'end start'],
	})

	const lgParallax = useTransform(scrollYProgress, [0, 1], [0, -2500])

	return (
		<motion.div
			ref={parallaxRef}
			style={{ y: lgParallax }}
			className={`absolute top-0 -z-10 ${className} `}>
			<img src={imgSrc} alt='' className='object-contain object-center' />
		</motion.div>
	)
}

export default BackgroundParallax
