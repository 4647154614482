/** @format */

// ** Components
import Typography from '@/components/Typography'

const Hero = () => {
	return (
		<section className='lg:h-[60vh] md:h-[50vh] h-[45vh] bg-primary bg-opacity-90'>
			<div className='container mx-auto flex flex-col items-center justify-end md:gap-10 gap-4 w-full h-full text-center text-white'>
				<div className='absolute bg-whoWeAre w-full h-full -z-10 bg-cover bg-center'></div>
				<Typography
					variant={'4XL'}
					as={'h1'}
					className={'font-extrabold max-w-[48rem] md:leading-relaxed tracking-widest'}>
					OUR TEAM PROVIDE CREATIVE SOLUTIONS FOR YOUR CREATIVE IDEAS
				</Typography>
				<Typography variant={'LG'} as={'div'} className={'max-w-[70rem] md:mb-20 mb-10'}>
					<p className='leading-relaxed '>
						Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure, cum culpa et ab eum nihil
						sint alias facilis eos quam nesciunt fugit ea blanditiis, magni quis exercitationem
						magnam, reprehenderit aliquid
					</p>
				</Typography>
			</div>
		</section>
	)
}

export default Hero
