/** @format */

// ** Router
import { createBrowserRouter } from 'react-router-dom'

// ** Layouts
import LandingLayout from '../layouts/LandingLayout'

// ** routes
import { routes } from './routes'

// react router 6.22
export const router = createBrowserRouter([
	{
		element: <LandingLayout />,
		errorElement: <div>Error 404</div>,
		children: [...routes],
	},
])
