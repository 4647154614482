/** @format */

// ** React
import { useTranslation } from 'react-i18next'

// ** Contents
import { SERVICES, SERVICES_SECTION_TITLE } from '@/constants/homePage'

// ** Components
import Typography from '@/components/Typography'

const OurServices = () => {
	const { t } = useTranslation('translations')

	return (
		<section className='pt-12'>
			<div className='container mx-auto'>
				{/* Section Title */}
				<div className='flex justify-center items-center gap-3 mb-8 md:mb-16'>
					<Typography variant={'4XL'} as={'span'} className='text-secondary'>
						//
					</Typography>
					<Typography variant={'4XL'} as={'h2'} className='text-white font-bold'>
						{t(SERVICES_SECTION_TITLE)}
					</Typography>
				</div>

				<div className='flex flex-col gap-16 md:gap-36 lg:px-28'>
					{SERVICES.map((service, idx) => (
						<div
							key={service.id}
							className={`flex flex-col justify-between overflow-hidden items-stretch md:items-center  ${
								idx % 2 === 0 ? 'md:flex-row' : 'md:flex-row-reverse'
							}`}>
							<Typography
								as={'span'}
								className={
									'text-[10rem] md:text-[20rem] leading-none text-center font-semibold text-white opacity-30 max-md:-mb-40 max-md:-mr-40'
								}>
								{t(service.id)}
							</Typography>
							<div className='w-full flex flex-col md:gap-6 gap-2 xl:max-w-xl '>
								<div>{service.img}</div>
								<Typography variant={'3XL'} as={'h2'} className={'text-white font-bold'}>
									{t(service.title)}
								</Typography>
								<Typography
									variant={'MD'}
									as={'p'}
									className={'text-white leading-loose tracking-wider'}>
									{t(service.subtitle)}
								</Typography>
							</div>
						</div>
					))}
				</div>
			</div>
		</section>
	)
}

export default OurServices
