/** @format */

import { useTranslation } from 'react-i18next'

// ** Components
import Typography from '@/components/Typography'

const NEWS = [
	{
		img: '',
		date: 'Oct 10',
		tag: 'Art',
		subtitle:
			'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Impedit, deleniti! Eaque cum ab ipsam totam mol    ',
	},
	{
		img: '',
		date: 'Oct 10',
		tag: 'marketing',
		subtitle:
			'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Impedit, deleniti! Eaque cum ab ipsam totam mol    ',
	},
	{
		img: '',
		date: 'Oct 10',
		tag: 'Copy write',
		subtitle:
			'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Impedit, deleniti! Eaque cum ab ipsam totam mol    ',
	},
]

const News = () => {
	const { t } = useTranslation('translations')

	return (
		<section className='container mx-auto w-full py-20'>
			{/* Section Title */}
			<div className='flex justify-center items-center  font-bold mb-20 text-center'>
				<Typography variant={'4XL'} as={'span'} className='text-secondary'>
					//
				</Typography>
				<Typography variant={'4XL'} as={'h2'} className='text-white font-bold'>
					{t('NEWS & INSIGHTS')}
				</Typography>
			</div>

			<div className='flex md:flex-row flex-col gap-16'>
				{NEWS.map((item) => (
					<div key={item.tag} className='flex flex-col gap-2'>
						<img src={item.img} alt='' className='border border-secondary h-72' />
						<div className='flex gap-1'>
							<Typography variant={'LG'} as={'span'} className='text-secondary'>
								{item.tag}
							</Typography>
							<Typography variant={'LG'} as={'span'} className='text-white'>
								|
							</Typography>
							<Typography variant={'LG'} as={'span'} className='text-white'>
								{item.date}
							</Typography>
						</div>
						<Typography variant={'LG'} as={'p'} className='text-white tracking-wide font-medium'>
							{item.subtitle}
						</Typography>
					</div>
				))}
			</div>
		</section>
	)
}

export default News
