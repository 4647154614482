/**
 * Do NOT forget to import this file in the root file
 *
 * @format
 */

import i18n from 'i18next'
// import Backend from 'i18next-http-backend'
import languageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import ar from './locales/ar/translations.json'
import en from './locales/en/translations.json'

i18n
	// .use(Backend)
	.use(languageDetector)
	.use(initReactI18next)
	.init({
		debug: false,
		fallbackLng: 'en',
		interpolation: {
			escapeValue: false,
		},
		// react: {
		// 	wait: true,
		// },
		// ns: ['translations','homePage', 'aboutPage'],
		// defaultNS: 'translations',
		// keySeparator: false,
		resources: {
			en: {
				translations: en,
			},
			ar: {
				translations: ar,
			},
		},
	})

export default i18n
