/** @format */

// ** Views
import Hero from '@/views/aboutPage/Hero'
import NumbersAboutUs from '@/views/aboutPage/NumbersAboutUs'
import OurExperts from '@/views/aboutPage/OurExperts'
import OurFounders from '@/views/aboutPage/OurFounders'
import WhoWeAre from '@/views/aboutPage/WhoWeAre'
import WhyChoseUs from '@/views/aboutPage/WhyChoseUs'

const AboutUs = () => {
	return (
		<div className='flex flex-col'>
			<Hero />
			<WhoWeAre />
			<WhyChoseUs />
			<NumbersAboutUs />
			<OurFounders />
			<OurExperts />
		</div>
	)
}

export default AboutUs
